import React, { useLayoutEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import { useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./new-redeemption.css";

import { useNavigate } from "react-router-dom";
import RedemptionService from "../services/redemption.service";

import StorageService from "../services/storage.service";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet";
import ashleyLook from "../Images/icon-2.png";
import ashleyLook1 from "../Images/icon-3.png";
import ashleyLook2 from "../Images/icon-4.png";
import ashleyLook3 from "../Images/icon-5.png";
import voucher from "../Images/gift-card.png";
import logo from "../Images/logo-new.png";

function RedemptionForm(props) {
  const TITLE = process.env.REACT_APP_API_SITE_TITLE;

  const [fields, setFields] = useState({ voucher: "" });
  const [errors, setErrors] = useState({});
  const [i_agree, setI_agree] = useState(false);
  const [captcha, setCaptcha] = useState(true);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [checkvoucherfield, setCheckvoucherfield] = useState(
    "The voucher number is required."
  );

  useLayoutEffect(() => {
    const keysToKeep = ["@secure.s.acessTokens", "theme", "@secure.s.panel"]; // Specify the keys you want to keep
    function clearLocalStorageExcept(keysToKeep) {
      for (let key in localStorage) {
        if (!keysToKeep.includes(key)) {
          localStorage.removeItem(key);
        }
      }
    }

    clearLocalStorageExcept(keysToKeep);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields((preVal) => ({
      ...preVal,
      [name]: value,
    }));
  };

  const handleChangecheckbox = (e, value) => {
    setI_agree(!i_agree);
  };

  const submituserRegistrationForm = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setFields({ voucher: "" });

      let errors = {};
      const checkCount = 0;

      if (checkCount === 0) {
        if (i_agree) {
          var bodyFormData = new FormData();
          bodyFormData.append("certificate", fields.voucher);

          let voucher_two = fields.voucher;

          try {
            setLoading(true);
            const response = await RedemptionService.validateVoucher(
              bodyFormData
            );

            if (response.data.status.includes("true")) {
              secureLocalStorage.setItem("isVoucher", true);
              localStorage.setItem(
                "denomination",
                response.data.data[0].denomination
              );
              // StorageService.setVoucherStatus(true);
              // StorageService.setDenomination(
              //   response.data.data[0].denomination
              // );

              const value = response.data.data[0].redirect;
              const status_value = response.data.status;
              if (status_value.includes("true")) {
                if (value === "userform") {
                  StorageService.setVoucher(voucher_two);
                  localStorage.setItem("voucher", voucher_two);
                  secureLocalStorage.setItem("Redemption", {
                    voucher_two: voucher_two,
                  });
                  window.scrollTo(0, 0);
                  // navigate("/survey-form");
                  navigate("/user-form");
                }
              }
            }

            if (!response.data.status.includes("true")) {
              if (
                !response.data.status.includes("true") &&
                response.data.message.redirect !== undefined &&
                response.data.message.redirect.includes("cert_details")
              ) {
                StorageService.setCertStatus(true);
                const detail_data = response.data.message.data;
                secureLocalStorage.setItem(
                  "cert_details",
                  JSON.stringify({
                    fname: detail_data.first_name,
                    lname: detail_data.last_name,
                    address: detail_data.address,
                    city: detail_data.city,
                    state: detail_data.state,
                    zip: detail_data.zip,
                    phone: detail_data.phone,
                    email: detail_data.email,
                    cardImage: detail_data.cardImage,
                    cardName: detail_data.cardName,
                  })
                );
                navigate("/cert_details");
                // secureLocalStorage.push({
                //   pathname: "/cert_details",
                // });
              } else {
                errors["voucher"] = response.data.message;
                setCheckvoucherfield(response.data.message);

                setErrors(errors);

                return false;
              }
            }
            setLoading(false);
          } catch (err) {
            setLoading(false);

            console.log(err);
            errors["voucher"] = err.response.data.message;
            setErrors(errors);
            console.log(err);

            console.log(err.response.data.message);
          }
        }
      }
    }
  };

  function onCaptchaChange(value) {
    if (value === null) {
      setCaptcha(false);
    } else {
      setCaptcha(true);
    }
  }

  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    if (!fields["voucher"]) {
      formIsValid = false;
      errors["voucher"] = checkvoucherfield;

      setErrors(errors);

      return false;
    }

    if (!i_agree) {
      formIsValid = false;
      errors["checkederror"] = "Select the checkbox";
      setErrors(errors);

      return false;
    }
    if (!captcha) {
      formIsValid = true;
      errors["checkederrorcaptcha"] = "Captcha not verified";
      setErrors(errors);

      return false;
    }
    return formIsValid;
  };

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>

      <form className="redem-home" onSubmit={submituserRegistrationForm}>
        <div className="voucher-section">
          <div className="voucher-entry">
            <img src={logo} alt="logo..." className="logo-redem" />
            <div className="read-carefully">
              {process.env.REACT_APP_REMPTIONTITLE}.
              <span>
                Note: Voucher Numbers are Case Sensitive with no spaces.
              </span>
            </div>
            <div className="voucher-input">
              <img src={voucher} alt="voucher" />
              <input
                type="text"
                maxLength="11"
                placeholder="Enter Voucher Number"
                name="voucher"
                value={fields.voucher || ""}
                onChange={handleChange}
              />
            </div>
            <div className="errorMsg">{errors.voucher}</div>
            <div className="form-check info-check">
              <input
                className="form-check-input"
                type="checkbox"
                name="test"
                value={fields.checkbox}
                onChange={handleChangecheckbox}
                id="flexCheckChecked"
              />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                YES, I HAVE READ THIS INFORMATION
              </label>
              <div className="errorMsg">{errors.checkederror}</div>
            </div>
            <div className="overflow-hider captch-redemp">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_API_GOOGLESITEKEY}
                onChange={onCaptchaChange}
              />
              <div className="errorMsg">{errors.checkederrorcaptcha}</div>
            </div>
            <input type="submit" />
          </div>
        </div>
        <div className="infor-section">
          <ul>
            <li className="active">
              <div className="info-icon">
                <img src={ashleyLook} alt="" className="w-100" />
              </div>
              <div className="info-text">
                <h5>BE ON THE LOOK OUT!</h5>
                Expect an email(s) within 5 business days from{" "}
                <a href={"mailto:" + process.env.REACT_APP_DESC_REWARD}>
                  {process.env.REACT_APP_DESC_REWARD}
                </a>
                ,{" "}
                <a href={"mailto:" + process.env.REACT_APP_PREP}>
                  {process.env.REACT_APP_PREP}
                </a>{" "}
                or{" "}
                <a href={"mailto:" + process.env.REACT_APP_MARKET_PLACE}>
                  {process.env.REACT_APP_MARKET_PLACE}
                </a>
                . Please add these email addresses to your safe senders list in
                your email setting.
                <span>
                  Once you receive your email(s) you may use the reward
                  virtually or request plastic cards to be mailed.
                </span>
              </div>
            </li>
            <li>
              <div className="info-icon">
                <img src={ashleyLook1} alt="" className="w-100" />
              </div>
              <div className="info-text">
                Please make sure to monitor your SPAM and JUNK folders as well.
              </div>
            </li>
            <li>
              <div className="info-icon">
                <img src={ashleyLook2} alt="" className="w-100" />
              </div>
              <div className="info-text">
                Once you receive and action your email from{" "}
                <a href={"mailto:" + process.env.REACT_APP_DESC_REWARD}>
                  {process.env.REACT_APP_DESC_REWARD}
                </a>
                ,{" "}
                <a href={"mailto:" + process.env.REACT_APP_PREP}>
                  {process.env.REACT_APP_PREP}
                </a>{" "}
                or{" "}
                <a href={"mailto:" + process.env.REACT_APP_MARKET_PLACE}>
                  {process.env.REACT_APP_MARKET_PLACE}
                </a>
                , you can use the reward card virtually or request a card to be
                mailed to you.
              </div>
            </li>
            <li className="pb-0">
              <div className="info-icon">
                <img src={ashleyLook3} alt="" className="w-100" />
              </div>
              <div className="info-text">
                If you have any questions, for the fastest service contact us on
                our live chat, in the lower right hand corner Or contact us via
                email at{" "}
                <a href={"mailto:" + process.env.REACT_APP_DESC_CUSTOMERCARE}>
                  {process.env.REACT_APP_DESC_CUSTOMERCARE}
                </a>{" "}
                or call us at{" "}
                <a href={"tel:" + process.env.REACT_APP_DESC_CONTACTUS}>
                  {process.env.REACT_APP_DESC_CONTACTUS}
                </a>
                <span>
                  Our business hours are Monday - Friday, 8:00am - 7:00pm EST
                </span>
              </div>
            </li>
            <div className="new-footer">
              <p>
                © Copyright {new Date().getFullYear()}. All rights reserved.{" "}
                {process.env.REACT_APP_API_SITE_TITLE}.
              </p>
            </div>
          </ul>
        </div>
        <div className="clearfix"></div>
      </form>
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </>
  );
}
export default RedemptionForm;
